
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/courselearn";

@Options({})
export default class extends Vue {
  private showSubmit = false;
  private facePic = "";
  private identifyForm = {
    faceUrl: "",
    videoId: "",
  };
  private picId = "";
  private loading = false;
  private fileList = [];

  mounted() {
    this.identifyForm.videoId = this.$route.query.videoId as any;
    this.makeSure();
  }

  async makeSure() {
    this.identifyForm.faceUrl = "";

    const identifyRes: any = await api.facecheck({
      faceUrl: this.identifyForm.faceUrl,
      videoId: this.identifyForm.videoId,
      itemId: Number.parseInt(this.$route.query.itemId as any),
      classId: Number.parseInt(this.$route.query.classId as any),
    });

    this.loading = false;

    this.$toast.success(identifyRes.msg);
    const params = {
      id: this.$route.query.classId,
      itemId: this.$route.query.itemId,
      videoId: identifyRes.data.videoMeta.videoId,
      playAuth: identifyRes.data.playAuth,
      coverURL: identifyRes.data.videoMeta.coverURL,
    };
    this.$router.push({ path: "/app/courseplay", query: params });
  }
}
